import styles from './Message.module.css';
import axiosCustom from '../../utilities/axiosCustom';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import io from 'socket.io-client';
var socket=null;


/*
Todo:
[] Add Time to sidebar
[] Subscribe to websocket thread for live updates in the sidebar.
*/

function formatDate(date) {
  return date.toLocaleString('en-US', { month: 'long', day: 'numeric' });
}

var MessageThread = ({name, description, dateMostRecent, isSelected, isNew, nameMostRecent}) => {
	// const currentDate = new Date();
	const dateFormatted = new Date(dateMostRecent);

	const styling = isSelected ? "list-group-item list-group-item-action active text-white rounded-0 h-100" : "list-group-item list-group-item-action list-group-item-light rounded-0 h-100"
	return (
	  <a href="#" className={styling}>
	    <div className="media">
	      {/*<img src="https://bootstrapious.com/i/snippets/sn-chat/avatar.svg" alt="user" width="50" className="rounded-circle" />*/}
	      <div className="media-body ml-4">
	        <div className="d-flex align-items-center justify-content-between mb-1">
	        	<h6 className="mb-0">{name}</h6><small className="small font-weight-bold">{formatDate(dateFormatted)}</small>
	        </div>
	        	<p className={"font-italic mb-0 text-small" + isSelected ? "" : "text-muted"} >{nameMostRecent+ ': ' +description?.substr(0,100)}</p>
	      </div>
	    </div>
	  </a>
	)
}

var ThreadsList = ({selectedThreadId, setSelectedThreadId, threads}) => {
	const navigate = useNavigate();

	return(
		<>
      <div className="col-12 px-0">
        <div className="bg-white">
          <div className="bg-gray px-4 py-2 bg-light">
            <p className="h5 mb-0 py-1">Recent</p>
          </div>
          <div className={styles.messagesBox}>
          	{
          		selectedThreadId === "new" ? <MessageThread isNew={true} isSelected={true} /> : ""
          	}
            <div className="list-group rounded-0">
            	{
            		threads.map((thread, index)=>{
            			return(
            				<div key={index} onClick={(e)=>{
            						e.preventDefault(); setSelectedThreadId(thread.id); 
												navigate("", { replace: true });
            					}} >
	            				<MessageThread
	            					key={index}
	            					isSelected={thread.id === selectedThreadId}
	            					name={thread.partnerName}
	            					description={thread.latestMessageText}
	            					dateMostRecent={thread.modifiedWhen}
	            					nameMostRecent={thread.latestMessageUserName}
	            				/>
          					</div>
          				)
            		})
            	}
            </div>
          </div>
        </div>
      </div>
		</>
	)
}

export default ThreadsList;