import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const instance = axios.create({
});

instance.interceptors.response.use(function (response) {
    if(response.headers['x-has-active-subscription'] && response.headers['x-user-data']){
        +response.headers['x-has-active-subscription'] ? localStorage.setItem('hasActiveSubscription', 1) : localStorage.setItem('hasActiveSubscription', 0);
        const userData = JSON.parse(response.headers['x-user-data']);
        localStorage.setItem('firstName', userData.firstName);
        localStorage.setItem('lastName', userData.lastName);
    }

    return response;
},
function(error){
    if(error.response.status === 403){
        localStorage.clear();
        return Promise.reject(error);
    }
});

export default instance;