import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import PostListItem from '../../components/PostListItem';
import axiosCustom from '../../utilities/axiosCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';

import LocationInputQ from '../../components/LocationInputQ';

function SearchResults({ searchResults, loading }) {
    useEffect(() => {
    }, [searchResults]);
    if (loading) {
      return <h4 className='mx-auto'>Loading...</h4>;
    }
    if (searchResults.length === 0) {
      return <h5 className='mx-auto text-body-secondary'>No posts were found.</h5>;
    } else {
      return (
        <>
          {searchResults?.map((post, key)=>{
              return <PostListItem
                  title={post.title} 
                  description={post.description}
                  id={post.id}
                  author= {post.firstName +" " +post.lastName }
                  key={key}
                  createdDateTime={post.createdDateTime}
                  appendToLink={'search'}
              />
          })}
        </>
      );
    }
}

function SearchPosts() {
    const location = useLocation(),
          navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setIsLoading] = useState(false);

    // Populate state with the query values.
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      setSearch(params.get('search') || '');
    }, []);

    // Update URL when state changes
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      if(search.length > 0){
        params.set('search', search);
      }
      navigate(`?${params.toString()}`, { replace: true });
    }, [search]);
    
    // Whenever the search query is edited by any of the components, run the search again.
    useEffect(()=>{
      runSearch();
    }, [location.search])

    var handleSearch = function(event){
        event.preventDefault();
        if(event.key === 'Enter' || event.type==='click') {
          runSearch();
        }
    }

    var runSearch = function(){
        setIsLoading(true);
        axiosCustom.get('/api/posts/search' + location.search)
        .then(response => {
            if (response.data) {
            setIsLoading(false);
            setSearchResults(response.data.data);
        }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error('Search error:', error);
        });
    }

    return (
      <>
        <Container>
          <Row className="mt-3 mb-3">
            <Col l="6" className='d-flex justify-content-end'>
              <Button href='/posts/create' style={{marginLeft: "5px", marginRight: "5px", float: "right"}} className="buttonWithOutline" variant="outline-primary">Create Post <FontAwesomeIcon icon={faSquarePlus} /></Button>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4" md={12} lg={3} style={{border: '1px solid right'}}>
              <b>Filters</b>
              <br />
              <b style={{float: 'left'}} >Location</b>
              <br />
              <LocationInputQ
                runSearch = {runSearch}
              />
              <br />
              
              <hr />
            </Col>
            
            <Col md={12} lg={9}>
              <Row className="w-100">
                  <Col className="p-0" xs={11} sm={10}>
                    <Form.Control
                      name="search"
                      type="text"
                      placeholder="Search Job Posts"
                      // style={{ height: '50px', fontSize: '20px', width: '88%' }}
                      // onKeyUp={handleSearch}
                      value={search}
                      onChange={(e)=>{setSearch(e.target.value)}}
                    />
                  </Col>

                  <Col className="p-0" xs={1} sm={2}>
                    <Button id="button-addon2" onClick={handleSearch} variant="outline-primary"> Search </Button>
                  </Col>
              </Row>

              <Row>
                <SearchResults searchResults={searchResults} loading={loading} />
              </Row>
              
            </Col>

          </Row>
        </Container>
      </>
    );
}

export default SearchPosts;