import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function PostListItem({ title, description, id, author, createdDateTime, appendToLink  }) {
    const navigate = useNavigate();

    let handleClick = function(event){
      event.preventDefault();
      navigate(`/posts/${id}/?${appendToLink}`);
    }

    function daysAgo() {
        const now = new Date();
        const givenDate = new Date(createdDateTime);
        const timeDifference = now - givenDate;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
        if (daysDifference === 0) {
            return "Today";
        } else if (daysDifference === 1) {
            return "Yesterday";
        } else {
            return `${daysDifference} days ago`;
        }
    }

    return (
        <>
            <Row className='post-list-item' onClick={handleClick} className="align-items-center">
                {/* <Col xs="1" style={{height: '100%'}} className="d-flex align-items-top" >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="currentColor"
                    >
                        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                    </svg>
                </Col> */}
                <Col xs="11">
                    <Row>
                        <Col className="p-0" xs="10">
                            <h4 className='d-inline d-flex justify-content-left'> { title } </h4>
                        </Col>
                        <Col className="p-0">
                            <p style={{float: 'right'}}>{daysAgo()}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0">
                            <p className='text-left text-muted'> By { author } </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0">
                            <p className='text-left'> { description.substring(0,400) + ' ' + (description.length > 400 ? '...':'') } </p>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
            <hr />
        </>
  );
}
export default PostListItem;