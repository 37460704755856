import React, { useState, useEffect } from 'react';
import axiosCustom from '../../utilities/axiosCustom';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

import SkillTagsQ from '../../components/SkillTagsQ';

import LocationInputQ from '../../components/LocationInputQ';
import SearchListItem from '../../components/SearchListItem';

function SearchResults({ searchResults, loading }) {
    if (loading) {
      return <h4 className='mx-auto'>Loading...</h4>;
    }
    if (searchResults.length === 0) {
      return <h4 className='mx-auto text-body-secondary'>No Results Found</h4>;
    } else {
      return (
        <>
          {searchResults.map(function (item, key) {
            return (
              <SearchListItem
                key={key}
                profilePicture={item.profilePicturePresignedLink}
                headline={item.headline}
                location={ item.cityName && item.cityName + ', ' + item.stateId }
                firstname={item.firstName}
                lastname={item.lastName}
                businessName={item.businessName}
                isBusiness={item.isBusiness}
                id={item.id}
              />
            );
          })}
        </>
      );
    }
}

function SearchUsers() {
    const [search, setSearch] = useState(''),
          [searchResults, setSearchResults] = useState([]),
          [loading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleEnter = (event) => {
      if(event.key === 'Enter'){
        runSearch();
      }
    }

    const runSearch = function(event){
      setIsLoading(true);

      axiosCustom.get('/api/user/search' + window.location.search)
      .then(response => {
        if (response.data.users) {
          setIsLoading(false);
          setSearchResults(response.data.users);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });

    }

    useEffect(runSearch, []) // This is so that when a user navigates to the search with a query string, the search will run.

    // Populate state with the query values.
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      if(params.get('search')?.length ){
        setSearch(params.get('search'));
      }else{
        params.delete('search');
      }
    }, []);

    // Update URL when state changes
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      params.set('search', search);
      navigate(`?${params.toString()}`, { replace: true });
       // eslint-disable-next-line
    }, [search]);

	return(
        <>
            <Row className='justify-content-center'>
            </Row>
            <Row className="mt-3">
            </Row>
            <Row>
                <Col className="mb-4" sm={12} lg={3} style={{border: '1px solid right'}}>
                    <b>Filters</b>
                    <br />
                    <SkillTagsQ
                        runSearch = {runSearch}
                    />
                    <b style={{float: 'left'}} >Location</b>

                    <br />
                    <LocationInputQ
                        runSearch = {runSearch}
                    />
                    <br />

                    <hr />
                </Col>

                <Col sm={12} lg={9} className="p-0" >

                    <Row className='w-100' onKeyDown={handleEnter}>
                        <Col className="p-0" xs={10} sm={10}>
                            <Form.Control
                            type="text"
                            placeholder="Find the person, service, or business you are looking for"
                            value={search}
                            onChange={(event)=>{setSearch(event.target.value)}}
                            />
                        </Col>
                        <Col className="p-0" xs={2} sm={2}>
                            <Button id="button-addon2" onClick={runSearch} variant="outline-primary"> Search </Button>
                        </Col>
                    </Row>

                    <SearchResults searchResults={searchResults} loading={loading} />
                </Col>

            </Row>
        </>
	)
}

export default SearchUsers;