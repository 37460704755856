// import './Message.module.css';
import styles from './Message.module.css';
import React, { useState, useEffect } from 'react';
import axiosCustom from '../../utilities/axiosCustom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';

import io from 'socket.io-client';

var socket=null;
function formatDate(date) {
  return date.toLocaleString('en-US', { month: 'long', day: 'numeric' });
}

const MessageReply = ({messageText, time}) => {
	const currentDate = new Date(time);
	return(
		<>
      <div className="media w-50 mb-3">
        {/*<img src="https://bootstrapious.com/i/snippets/sn-chat/avatar.svg" alt="user" width="50" className="rounded-circle" />*/}
        <div className="media-body ml-3">
          <div className="bg-light rounded py-2 px-3 mb-2">
            <p className="text-small mb-0 text-muted">{messageText}</p>
          </div>
          <p className="small text-muted">{formatDate(currentDate)}</p>
        </div>
      </div>
		</>
	)
}

const MessageSent = ({messageText, time}) => {
	const currentDate = new Date(time);

	return (
    <div className="media w-50 ml-auto mb-3">
      <div className="media-body">
        <div className="bg-primary rounded py-2 px-3 mb-2">
          <p className="text-small mb-0 text-white">{messageText}</p>
        </div>
        <p className="small text-muted">{formatDate(currentDate)}</p>
      </div>
    </div>
	)
}

const MessagesThread = ( {selectedThreadId, threadName, refreshThreadsList} ) => {
	const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [messageInput, setMessageInput] = useState('');

  const [messages, setMessages] = useState([]);

  const getMessages = () => {
  	if(selectedThreadId){
	  	fetch(`/api/messages/${selectedThreadId}`, {
	  		headers: {
					'Content-Type': 'application/json',
	  		}
	  	})
	  	.then(response => response.json())
	  	.then(data => {
	  		setMessages(data.messages);
	  	})
	  	.catch(error => {
	  		alert('Could not get messages for threadId, ', selectedThreadId);
	  		console.error('getMessages error: ', error);
	  	})
  	}else{
  		setMessages([]);
  	}
  }
  useEffect(getMessages, [selectedThreadId]);

  useEffect(() => {
  	socket = io(window.origin);

    socket.emit('join thread', selectedThreadId);

    socket.on('connect', () => {
      console.log('Connected to WebSocket server successfully');
    });

    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      // setConnected(false);
    });

    socket.on('chat sent', (message) => {
    	if(parseInt(message.senderId) === parseInt(localStorage.userID)) {
    		message.isFromMe = 1;
    	}else{
    		message.isFromMe = 0;
    	}
      setMessages((prevMessages) => [...prevMessages, message]);
      refreshThreadsList();
      // scrollToBottom();
    });

    // Clean up on component unmount
    return () => {
    		// socket.emit('leave thread', {selectedThreadId: selectedThreadId, userId: parseInt(localStorage.userID) } );
    		socket.off();
        socket.disconnect();
    };
  }, [selectedThreadId]);


  const onSend = (e) => {
  	e.preventDefault();
    if (messageInput.trim()) {
      const messageData = {
        threadId: selectedThreadId,
        userId: localStorage.userID,
        messageInput
      };
      socket.emit('chat sent', messageData);
      setMessageInput('');
      // scrollToBottom();
  	}
  }

  const scrollToBottom = () => {
		document.querySelector("#messages-scroll-container").scrollTop = document.querySelector("#messages-scroll-container").scrollHeight
  }
  useEffect(scrollToBottom, [messages]);

  return (
  	<>
	    <div className={styles.chatMessagesContainerAboveTextInput}>
	      <div className="bg-gray px-4 py-2 bg-light">
	      	<p className="h5 mb-0 py-1">Chat with {threadName}</p>
	      </div>
	      <div className='overflow-y-scroll h-100' id="messages-scroll-container">
	        {messages.map((message, index) => (
	          message.isFromMe? (
	            <MessageSent key={index} messageText={message.messageText} time={message.createdDateTime} />
	          ) : (
	            <MessageReply key={index} messageText={message.messageText} time={message.createdDateTime} />
	          )
	        ))}
	      </div>
	   	</div>

	   	<div className='position-absolute w-100 bottom-0'>
	    	<form className="bg-light d-flex" onSubmit={onSend}>
		      <input
		        value={messageInput}
		        onChange={(e) => setMessageInput(e.target.value)}
		        type="text"
		        placeholder="Type a message"
		        className="form-control rounded-0 border-0 py-4 bg-light flex-grow-1"
		      />

		      <div className="input-group-append">
						<button
			      	type="submit"
			      	className="btn btn-link"
			      >
			      <FontAwesomeIcon icon={faPaperPlane} />
			      </button>
		      </div>

		    </form>
	    </div>
      </>
  );

}

export default MessagesThread;