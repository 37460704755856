import React, { useEffect, useState } from 'react';
import axiosCustom from './utilities/axiosCustom';

import { Navbar, Nav, Container, Button, Modal } from 'react-bootstrap';

function signOut(event) {
    event.preventDefault();
    localStorage.clear()
    axiosCustom.post('/api/user/sign-out')
    .then(() => {
        window.location.href = '/?sign-out=true';
    })
    .catch((error)=>{
        // Sign out should return 403.
        window.location.href = '/?sign-out=true';
    })
}

function InviteFriendsModal() {
    const [show, setShow] = useState(false);
    const [inviteCode, setInviteCode] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function getCode(){
        axiosCustom.get('/api/user/get-invite-code')
        .then(response => {
            setInviteCode(response.data.inviteCode);
        })
        .catch(error => {
            console.error('Fetch invite code error:', error);
        });
    }
    useEffect(getCode, []);

    return (
        <>
            <Button variant="outline-primary" onClick={handleShow}>Invite Friends to The Perfect Part-Time!</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite Friends to The Perfect Part-Time!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>We are an exclusive network that requires an in-network invitation to join.</p>
                    <p>Your exclusive invitation code is: <b> {inviteCode}</b></p>
                    <p>Share this code with your friends and favorite vendors so that they can join The Perfect Part-Time.</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


function Navigation() {
  return (
    <Navbar className="mb-0" bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="/profiles"><img alt="the-perfect-part-time-logo" style={{width: "300px", marginTop:"10px"}} src="/Perfect-Part-Time-Logo_Horizontal_Black-and-pink.png" /></Navbar.Brand>
            
            {
                localStorage.userID && localStorage.hasActiveSubscription!=='false' ? 
                <>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <InviteFriendsModal style={{float: 'right'}} />
                    </Nav>
                </Navbar.Collapse>
                </>
                :
                ""
            }


            {(!localStorage.userID) ? 
            // signed out
            <Navbar.Text><a href="/">Sign In</a></Navbar.Text>
            :
            <Navbar.Text><form onSubmit={signOut}><button type="submit">Sign Out</button></form></Navbar.Text>
            }
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
    </Navbar>

  );
}

export default Navigation;