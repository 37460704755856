import React from 'react';
import { Container } from 'react-bootstrap';
import FloorNavigation from './components/FloorNavigation';
import Navigation from './Navigation';
import {
  Outlet
} from "react-router-dom";

function App() {
  return (
      <>
        <div className="App">
          <Navigation />
          <Container className='position-relative app-container'>
            <Outlet />
          </Container>
        </div>
        {localStorage.userID ? <FloorNavigation /> : ""}
      </>
  );
}

export default App;