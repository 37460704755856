import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faMessage } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import axiosCustom from '../utilities/axiosCustom';

var FavoriteButton = function({ id }){
    // const { id } = useParams();
    var [isFavorite, setIsFavorite] = useState(false);
    var [isDisabled, setIsDisabled] = useState(false);

    var handleClickFavorite = function(event){
        event.preventDefault();
        setIsDisabled(true);
        axiosCustom.get(`/api/user/favorite/${id}/${!isFavorite}`)
        .then(() => {
            setIsFavorite(!isFavorite);
            setIsDisabled(false);
        })
        .catch(error => {
            console.error('Error favoriting user:', error);
        });
    }
    
    useEffect(function(){
      axiosCustom.get(`/api/user/is-favorite/${id}`)
      .then(response => {
        setIsFavorite(response.data.isFavorite);
      })
      .catch(error => {
        console.error('Error checking if favorite:', error);
      });
    },[]);
  
    return(
      <>
        <Button onClick={handleClickFavorite} style={{marginLeft: "5px", marginRight: "5px", position: "relative", top: "50%", transform: "translateY(-50%)"}} className="buttonWithOutline" variant={`${isFavorite ? '' : 'outline-'}primary`} ><FontAwesomeIcon icon={faHeart} /></Button>
      </>
    );
}

function SearchListItem({ profilePicture, headline, location, firstname, lastname, id, businessName, isBusiness }) {
    const navigate = useNavigate();

    let handleClick = function(event){
      event.preventDefault();
      navigate(`/profile/${id}?search`);
    }

    return (
        <Row className='mt-3 searchListItem'>
            <Col xs={3} sm={2} onClick={handleClick}>
                <img src={profilePicture} alt="Profile Picture" className="img-fluid" />
            </Col>
            <Col className="p-0" xs={7} sm={8} style={{textAlign: 'left'}} onClick={handleClick}>
                <div className='justify-content-left'>
                    {isBusiness ?  <h4>{businessName}</h4> : <h4>{firstname + ' ' + lastname}</h4>}
                    <h5>{headline}</h5>
                    <p>{location}</p>
                </div>
            </Col>
            <Col className="p-0" xs={1} sm={1}>
                {
                    (parseInt(localStorage.userID) === id) ? "" :
                    <FavoriteButton id={id}/>
                }
            </Col>
            <Col className="p-0" xs={1} sm={1} >
                {
                    (parseInt(localStorage.userID) === id) ? "" :
                    <Button onClick={()=>{ navigate(`/messages/new?recipientId=${id}`, { replace: true }); } } style={{marginLeft: "5px", marginRight: "5px", position: "relative", top: "50%", transform: "translateY(-50%)"}} className="buttonWithOutline" variant="outline-primary"><a href={`/messages/new?recipientId=${id}`}><FontAwesomeIcon icon={faMessage} /> </a> </Button>
                }
            </Col>
        </Row>
  );
}
export default SearchListItem;