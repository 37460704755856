import React, { useState, useEffect } from 'react';
import axiosCustom from '../../utilities/axiosCustom';
import MessagesThread from './MessagesThread.js';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Message.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function NewMessage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [recipientName, setRecipientName] = useState('');
  const [recipientId, setRecipientId] = useState(null);


  const params = new URLSearchParams(location.search);

    function Enclosure({name}) {
        const [messageText, setMessageText] = useState('');
        const onCreateThread = (event) => {
            event.preventDefault();
            axiosCustom.post('/api/message/thread', {
              recipient: recipientId,
              messageText: messageText
            }, {
              headers: {
                'Content-Type': 'application/json',
              }
            })
            .then(response => {
              if (response.data.newThreadId) {
                navigate(`/messages/?threadId=${response.data.newThreadId}`, { replace: true });
              }
            })
            .catch((error) => {
              console.log('error creating a thread: ', error);
            });
        }

    return (
      <>

        <div className={styles.chatMessagesContainerAboveTextInput}>
          <div className="bg-gray px-4 py-2 bg-light">
            <p className="h5 mb-0 py-1">Message {name}</p>
          </div>
        </div>

        <div className='position-absolute w-100 bottom-0'>
          <form onSubmit={onCreateThread} className="bg-light d-flex">
            <input
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              type="text"
              placeholder="Type a message"
              className="form-control rounded-0 border-0 py-4 bg-light flex-grow-1"
            />

            <div className="input-group-append">
              <button
                type="submit"
                className="btn btn-link"
              >
              <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>

          </form>

        </div>

      </>

    )
  }

  const populateNewMessage = () => {
    if(params.get("recipientId")){
      const recipientId = parseInt(params.get("recipientId"));
      fetch(`/api/user/get/${recipientId}`)
      .then(response => response.json())
      .then(data => {
          setRecipientName(data.user.firstName + ' ' + data.user.lastName);
          setRecipientId(data.user.id);
          // If I am already in a thread with this user, then redirect to that thread.
          fetch('/api/message/threads')
          .then(response => response.json())
          .then(data => {
            data.threads.forEach((thread) => {
              if(thread.senderId === recipientId || thread.recipientId === recipientId){
                navigate('/messages?selectedThreadId='+thread.id)
              }
            })
          });
      })
      .catch((error) => {
          console.error('Error:', error);
      });
    }
  }

  useEffect(populateNewMessage, []);
  
  return (
    <>
      <div className={styles.container}>
        
        <div className="row rounded-lg no-gutters h-100 position-relative">
          <Col lg={12} className={styles.chatMessagesContainer}>
            <Enclosure name= {recipientName} />
          </Col>
        </div>
      </div>

    </>
  );
}

export default NewMessage;