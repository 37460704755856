import React, { useState } from 'react';
import axiosCustom from '../utilities/axiosCustom';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CreatePost = function() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const navigate = useNavigate();
    
    var handleSavePost = function(event){
        event.preventDefault();
        axiosCustom.post('/api/post', {
          title: title,
          description: content
        })
          .then(response => {
            console.log('Successfully created post:', response.data.newPostId);
            navigate('/posts/' + response.data.newPostId);
            // setTitle('');
            // setContent('');
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }

    return (
        <>
            <h1>Create a post</h1>
            <Form>
                <Form.Group as={Row} controlId="post-title">
                    <Form.Label className="text-left" column xs="2">Post Title</Form.Label>
                        <Form.Control value={title} onChange={(event)=>{setTitle(event.target.value)}} placeholder="Post Title" />
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="post-body">
                    <Form.Label className="text-left" column xs="2">Post Body</Form.Label>                       
                    <Form.Control value={content} onChange={(event)=>{setContent(event.target.value)}} placeholder="Post Body" as="textarea" rows={10} />
                </Form.Group>
                <Button onClick={handleSavePost} className="ml-auto" variant="primary">
                    Save Post
                </Button>
            </Form>
        </>
    );
};

export default CreatePost;
