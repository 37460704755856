import './index.css';
import './bootstrap-custom.css';
import '@mantine/core/styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { MantineProvider } from '@mantine/core';
import App from './App';

import SignIn from './pages/SignIn';
import SearchProfiles from './pages/search/SearchUsers';
import SearchPosts from './pages/search/SearchPosts';

import MyProfile from './pages/profile/MyProfile';
import PublicProfile from './pages/profile/PublicProfile';
import EditProfile from './pages/profile/EditProfile';

import CreateAccount from './pages/createAccount/CreateAccount';
import ResetPassword from './pages/ResetPassword';
import Messages from './pages/messages/Messages';
import NewMessage from './pages/messages/NewMessage';
import Favorites from './pages/favorites/Favorites';
import CreatePost from './pages/CreatePost';
import Post from './pages/Post';

const AuthMiddleware = () => {

    const isSignedIn = localStorage.userID ? true : false;
    const publicPages = ['/create-account', '/create-account/join', '/create-account/welcome', '/'];
    
    if(isSignedIn && +localStorage.hasActiveSubscription){
        return <Outlet />
    }else if(!isSignedIn){
        return <Navigate to="/?login-middleware-redirect" />
    }else if(!+localStorage.hasActiveSubscription && !publicPages.includes(window.location.pathname)){
        return <Navigate to="/create-account/join" />
    }else{
        return <Navigate to="/?login-middleware-redirect" />
    }
};

const SignInPageMiddleware = () => {
    const isSignedIn = localStorage.userID ? true : false;
    if(isSignedIn){
        return <Navigate to="/profiles" />
    }else{
        return <SignIn />
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <script src="https://cdn.tailwindcss.com"></script>
        <MantineProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={<SignInPageMiddleware />} />
                        <Route path="create-account" element={<CreateAccount />} />
                        <Route path="create-account/join" element={<CreateAccount isJoinView={true} />} />
                        <Route path="create-account/welcome" element={<CreateAccount isWelcomeView={true} />} />
                        <Route path="reset-password" element={<ResetPassword />} />

                        <Route element={<AuthMiddleware />}>
                            <Route path="profiles" element={<SearchProfiles />} />
                            <Route path="posts" element={<SearchPosts />} />
                            
                            <Route path="profile/:id" element={<PublicProfile />} />
                            <Route path="profile" element={<MyProfile />} />
                            <Route path="profile/edit" element={<EditProfile />} />

                            <Route path="messages" element={<Messages />} />
                            <Route path="messages/new" element={<NewMessage />} />
                            <Route path="favorites" element={<Favorites />} />
                            <Route path="posts/create" element={<CreatePost />} />
                            <Route path="posts/:id" element={<Post />} />
                            <Route path="posts/:edit/:id" element={<Post />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </MantineProvider>
    </React.StrictMode>
);