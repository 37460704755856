import React, { useState, useEffect } from 'react';
import axiosCustom from '../../utilities/axiosCustom';

import FavoritePosts from './FavoritePosts'
import FavoriteUsers from './FavoriteUsers'
import Posts from '../../pages/Posts';
import { Nav } from 'react-bootstrap';

function Favorites() {
  var [selectedView, setSelectedView] = useState('users'); // profiles || posts

  return (
    <>
      <Nav fill variant="pills">
        <Nav.Item>
          <Nav.Link onClick={()=>{setSelectedView('users')}} active={selectedView === 'users'}>
            Users
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link onClick={()=>{setSelectedView('posts')}} active={selectedView === 'posts'}>
            Posts
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <div>
        {selectedView === 'users' ? <FavoriteUsers userId={localStorage.userID} /> :""}
        {selectedView === 'posts' ? <FavoritePosts /> :""}
      </div>
    </>
  );
}

export default Favorites;