import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchListItem from '../../components/SearchListItem';
import axiosCustom from '../../utilities/axiosCustom';

const FavoriteUsers = ( { userId, firstName } ) => {
    var [ favorites, setFavorites ] = useState([]);
    var getFavorites = function(){
        fetch('/api/user/favorites/'+userId||'')
        .then(response => response.json())
        .then(function(data){
            setFavorites(data.users);
        })
        .catch((error) => {
            console.error('Error getting favorites:', error);
        });
    }
    useEffect(getFavorites, [userId]);
    const possessiveName = name => name.endsWith('s') ? `${name}'` : `${name}'s`;

    return ( 
        <>
        <div>
            { firstName?.length ? <h2>{possessiveName(firstName)} Favorite Profiles</h2> : <h2>My Favorite Profiles</h2> }
            {favorites.map(function (item, key) {
                return (
                    <SearchListItem
                        key={ key }
                        profilePicture={ item.profilePicturePresignedLink }
                        headline={item.headline}
                        location={ item.cityName + ', ' + item.stateId }
                        firstname={ item.firstName }
                        lastname={ item.lastName }
                        id={ item.id }
                    />
                );
                })
            }
        </div>
        </>
    );

};

export default FavoriteUsers;