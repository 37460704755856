import React, {useState, useEffect} from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import PostListItem from '../components/PostListItem';
import axiosCustom from '../utilities/axiosCustom';

const Posts = function() {
    const [posts, setPosts] = useState([]);

    const fetchPosts = function(){
        fetch('/api/posts')
        .then(response => response.json())
        .then(data => {
            setPosts(data.posts);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    useEffect(fetchPosts, []);
    const possessiveName = name => name.endsWith('s') ? `${name}'` : `${name}'s`;

    return (
        <>
            <Row className="mt-3">
                <Col>
                    <h2>{localStorage?.firstName ? possessiveName(localStorage?.firstName) : possessiveName(localStorage?.firstName) } Posts</h2>
                </Col>
                <Col>
                    <Button href="/posts/create" style={{marginLeft: "5px", marginRight: "5px"}} >New Post</Button>
                </Col>
            </Row>
            
            {posts?.map((post, key)=>{
                return <PostListItem
                    title={post.title} 
                    description={post.description}
                    id={post.id}
                    author= {post.firstName +" " +post.lastName }
                    key={key}
                    createdDateTime={post.createdDateTime}
                    appendToLink={'my-posts'}
                />
            })}
        </>
    );
};

export default Posts;