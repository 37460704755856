import React, { useEffect, useState } from 'react';
import { Slider } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

var RadiusQ = ( { runSearch } ) => {
	const [radius, setRadius] = useState(10);

  const [localRadius, setLocalRadius] = useState(10);

  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);

  // Populate state with the query values.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if(params.get('radius')) {
      setRadius(parseInt(params.get('radius')));
    }
  }, []);

  useEffect(runSearch, [radius]);

  // Update URL when state changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramsRadius = params.get('radius');

    if(paramsRadius !== radius){
      if(radius){
        params.set('radius', radius);
      }else{
        params.delete('radius');
      }
      navigate(`?${params.toString()}`, { replace: true });
    }
  }, [radius]);
  
  return (
    <>
    {params.get('location') ? 
      <>
        <b style={{float: 'left'}} >Radius: {radius}</b><br />
        <Slider
          min={2} max={80} step={2}
          value={localRadius}
          onChangeEnd={setRadius}
          onChange={setLocalRadius}
          color="pink"
        />
      </>
      : ""
    }
    </>
  );
}

export default RadiusQ;