import React, { useState, useEffect, useCallback, useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import {loadStripe} from '@stripe/stripe-js/pure';
import axiosCustom from '../../utilities/axiosCustom';

import { Form } from 'react-bootstrap';


// const stripeJs = async () => await import("@stripe/react-stripe-js");

// const { EmbeddedCheckoutProvider, EmbeddedCheckout } = await stripeJs();

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

var stripePromise = null;

function CreateAccount({isJoinView, isWelcomeView}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhoneState] = useState('');
  
  const setPhone = function(phone){
    var newPhone = '';
    function formatPhoneNumber(input) {
      // Remove any non-digit characters from the input
      const cleaned = input.replace(/\D/g, '');
      
      // Splice in dashes at the correct positions
      if (cleaned.length > 6) {
        return cleaned.slice(0, 3) + '-' + cleaned.slice(3, 6) + '-' + cleaned.slice(6);
      } else if (cleaned.length > 3) {
        return cleaned.slice(0, 3) + '-' + cleaned.slice(3);
      } else {
        return cleaned;
      }
    }

    if(phone.length<13){
      setPhoneState(formatPhoneNumber(phone));
    }
  }

  const [accountType, setAccountType] = useState('');

  const [inviteCode, setInviteCode] = useState('');

  const [displayError, setError] = useState('');

  const [createAccountStage, setCreateAccountStage] = useState('invite'); //invite, account, payment, or welcome
  
  const [stripeSessionId, setStripeSessionId] = useState(null);

  const [aboutMe, setAboutMe] = useState('');
  const [headline, setHeadline] = useState('');
  
  const stripeCheckoutRef = useRef(null);

  const submitUpdateHeadlineAndAbout = function(event){
    event.preventDefault();
    axiosCustom.put('/api/user/edit-headline-aboutme', {
      headline: headline,
      aboutMe: aboutMe
    })
    .then((response) => {
      window.location.href = "/profiles";
    })
    .catch((error) => {
      console.error("could not update headline and aboutMe. Error: ", error);
    });
  }

  // populate headline and aboutme in case the user somehow gets back to the join screen.
  useEffect(()=>{
    if(createAccountStage === 'welcome'){
      axiosCustom.get('/api/user/')
      .then((response) => {
        if (response.data.success) {
          setHeadline(response.data?.userObject?.headline);
          setAboutMe(response.data?.userObject?.aboutMe);
        }
      });
    }

    const providerInstance = stripeCheckoutRef.current;

    return(()=>{
      if(providerInstance){
        providerInstance.destroy();
      }
    })
  }, []);

  useEffect(()=>{
      // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    stripePromise = loadStripe('pk_test_51IW3dRLqqi2kZwcbD4OM2ppSwJOinuFdVjOinvijPOY4r0TM3anpi1F9a6hDvAtqfAAZvJJssNTFUrdLXK0Ry6FQ00HXlreTE6');
    if(isJoinView){
      setCreateAccountStage('payment');
    }
    if(isWelcomeView){
      setCreateAccountStage('welcome');
    }
  }, [])
  
  const onEnterAccount = async (event) => {

    event.preventDefault();

    try {
      const response = await axiosCustom.post('/api/create-account', {"firstName": firstName, "lastName": lastName, "email": email, "phone": phone, "password": password, "accountType": accountType, inviteCode: inviteCode},
        {headers: {'Content-Type': 'application/json'}}
      );
      if (response.data.success) {
        try {
          const response = await axiosCustom.post('/api/sign-in', {"email": email, "password": password});
          if(response.data.message === "invalid password"){
            console.error("Could not sign in after account creation. ")
            setError('Could not sign in after account creation.');
          }
          if(response.data.message === "bad-email"){
            setError('Invalid email.')
          }
          if(response.status === 200){
            // Successfully created the account, redirect to join subscription.
            localStorage.setItem('firstName', response.data.firstName);
            localStorage.setItem('lastName', response.data.lastName);
            localStorage.setItem('userID', response.data.userID);
            localStorage.setItem('hasActiveSubscription', false);

            window.location.href = "/create-account/join"
            setCreateAccountStage("payment");
            setError("");
          }
          console.log('create account response, ', response)
        } catch (error) {
          setError('Could not create account.');
          console.error(error);
        }

      } else {
        console.error(response.data);
        if(response.data.message === 'user-exists'){
          setError('Could not create account. User already exists');
        }else{
          setError('Could not create account.');
        }
      }

    } catch (error) {
      setError('Could not create account.');
      console.error(error);
    }
  };

  const onEnterInviteCode = (event) => {
    event.preventDefault();

    axiosCustom.get(`/api/user/check-invite-code/${inviteCode}`)
    .then((response) => {
      if (response.data.isValidCode) {
        setError("");
        setCreateAccountStage('account');
      } else {
        setError("We could not find that invite code.");
      }
    })
    .catch((error) => {
      setError("We could not find that invite code.");
      console.error('Error:', error);
    });

  }

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return axiosCustom.post("/api/payment/create-checkout-session")
      .then((response) => {
        setStripeSessionId(response.data.stripeSessionId);
        return response.data.clientSecret;
      });
    }, []);

  const options = {fetchClientSecret};
  
  const [isComplete, setIsComplete] = useState(false);

  const handleComplete = (isComplete) => {
    setIsComplete(true);
  }

  useEffect(()=>{
    if(isComplete){
      axiosCustom.get(`/api/payment/update-status`, {
        params: {
          session_id: stripeSessionId
        }
      })
      .then((response) => {
        setIsComplete(isComplete);
      })
      .catch((error) => {
        console.log('check-and-update-status error: ', error);
      });
    }
  }, [isComplete])

  return (
    <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">

              <div className="card-header bg-primary text-white">
                <h2>{isJoinView ? "Join" : "Create Account"}</h2>
              </div>
              <br />

              <Alert key='warning' variant='warning' style={{display: (displayError.length > 0 ? "": "none")}}>
                {displayError}
              </Alert>

              <div className="card-body">
                {
                  createAccountStage === "invite" && !isJoinView ?
                    <form onSubmit={onEnterInviteCode}>
                      <div className="form-group">
                        <label htmlFor="invite-code">Invite Code:</label>
                        <input type="text" className="form-control" id="invite-code" value={inviteCode} onChange={e => setInviteCode(e.target.value)} required />
                      </div>

                      <br />

                      <button type="submit" className="btn btn-primary btn-block">Continue</button>  
                    </form>
                    :""
                }
                {
                  createAccountStage === "account" && !isJoinView ?
                    <form onSubmit={onEnterAccount}>
                      <div className="form-group">
                        <label htmlFor="first-name">First Name: <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="first-name" value={firstName} onChange={e => setFirstName(e.target.value)} required />
                      </div>

                      <div className="form-group">
                        <label htmlFor="last-name">Last Name: <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="last-name" value={lastName} onChange={e => setLastName(e.target.value)} required />
                      </div>

                      <div className="form-group">
                        <label htmlFor="emailc">Email: <span className="text-danger">*</span></label>
                        <input type="email" className="form-control" id="emailc" value={email} onChange={e => setEmail(e.target.value)} required />
                      </div>

                      <div className="form-group">
                        <label htmlFor="phone">Phone: </label>
                        <input pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" type="tel" className="form-control" id="phone" value={phone} onChange={e => setPhone(e.target.value)} />
                        <small className="text-body-secondary" style={{float: 'left'}}>Providing your phone number is optional. Please format as: XXX-XXX-XXXX</small><br />
                      </div>
                      

                      <div className="form-group">
                        <label htmlFor="password">Password: <span className="text-danger">*</span></label>
                        <input type="password" className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} required />
                      </div>
                      
                      <div className="form-group">
                        <label htmlFor="accountType">Role: <span className="text-danger">*</span></label>
                        <select className="form-control" id="accountType" value={accountType} onChange={e => setAccountType(e.target.value)} required>
                          <option value="">...</option>
                          <option value="worker">Searching for work.</option>
                          <option value="client">Searching to hire.</option>
                          <option value="both">Searching for work and to hire.</option>
                          <option value="networker">Looking to network.</option>
                        </select>

                      </div>

                      <br />

                      <button type="submit" className="btn btn-primary btn-block">Continue</button>  
                    </form>
                    :""
                }
                {
                  createAccountStage === "payment" && isJoinView ?
                  <>
                    <form action="/api/payment/create-checkout-session" method="POST">
                      <div id="checkout">
                          <EmbeddedCheckoutProvider
                            ref={stripeCheckoutRef}
                            stripe={stripePromise}
                            options={{
                              ...options ,
                              onComplete: handleComplete
                            }}
                          >
                            <EmbeddedCheckout />
                          </EmbeddedCheckoutProvider>
                      </div>
                      <br />
                    </form>
                    { isComplete ? <a href="/create-account/welcome"><button className="btn btn-primary btn-block">Continue</button></a> : "" }
                    </>
                    :""
                }
                {
                  isWelcomeView ?
                  <>
                    <p>Welcome to The Perfect Part-Time!</p>
                    <form>
                      <div className="form-group">
                        <label htmlFor="headline">Headline:</label>
                        <input required placeholder="What is your Perfect Part Time?" type="text" className="form-control" id="headline" value={headline} onChange={e => setHeadline(e.target.value)} required />
                        <small className="text-body-secondary">Tip: Include your profession OR what kind of work or help you’re searching for OR maybe you’re a busy mom who needs all the help 😊 </small>
                      </div>

                      <Form.Group className="form-group">
                        <Form.Label htmlFor="about-me">About Me</Form.Label>
                        <Form.Control required={true} value={aboutMe} name="about-me" onChange={(v)=>{setAboutMe(v.target.value)}} as="textarea" rows={4} placeholder="About me" style={{ whiteSpace: 'pre-wrap' }} />
                        <small className="text-body-secondary" style={{float: 'left'}}>{(500-aboutMe.length)} characters remaining</small>
                      </Form.Group>
                      <button onClick={submitUpdateHeadlineAndAbout} type="submit" className="btn btn-primary btn-block">Continue</button>
                    </form>
                  </>
                  :""
                }
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default CreateAccount;