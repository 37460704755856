import React, { useState } from 'react';
import axiosCustom from '../utilities/axiosCustom';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosCustom.post('/api/sign-in', {"email": email, "password": password});
      if(response.data.message === "invalid password"){
        setErrorMessage('Sign in failed. Please check your email and password and try again.');
      }
      localStorage.setItem('userID', response.data.id);

      window.location.href= '/profiles';
    } catch (error) {
      setErrorMessage('Sign in failed. Please check your email and password and try again.');
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="card border-0 shadow">
          <div className="card-header bg-primary text-white rounded-top">
            <h2 className="text-center">Sign In</h2>
          </div>
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input type="password" className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} required />
              </div>
              
              <br />
              <button type="submit" className="btn btn-primary btn-block">Sign In</button>
              <br />
              
            </form>
            
            <a className="linkNoStyle" href="/create-account"><button className="btn btn-primary btn-block">Create Account</button></a><br />
            <a className="linkNoStyle" href="/reset-password"><button className="btn btn-secondary btn-block">Reset Password</button></a><br />

          </div>
        </div>
      </div>
    </div>

  );
}

export default SignIn;