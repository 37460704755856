import React, { useEffect, useState } from 'react';
import { Select, TagsInput } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosCustom from '../utilities/axiosCustom';

var SkillTagsQ = function({runSearch}){
  const [skillCategories, setSkillCategories] = useState([]);
  const [skillCategoryEntries, setSkillCategoryEntries] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  // Populate state with the query values.
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if(params.get('skills') && params.get('skills') !== 'null'){
      setSkillCategoryEntries(params.get('skills').split(','));
    }

  }, []);

  // Update URL when state changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // params.set('skills', skillCategories.join(','));
    if( skillCategories.length === 0 ){
      params.delete('skills');
    }

    navigate(`?${params.toString()}`, { replace: true });
  }, [skillCategories]);


    useEffect(function(){
        const params = new URLSearchParams(location.search);
        axiosCustom.get('/api/get-skill-categories')
          .then(response => {
            setSkillCategories(response.data);
          })
          .catch(error => {
            console.error('Error fetching skill categories:', error);
          });

        if (params.get('skills')) {
          setSkillCategories(params.get('skills').split(','));
        }
    
    }, [])

  var handleSkillCategoryChange = function(newValue){
    const params = new URLSearchParams(location.search);
    if(newValue.length > 0){
      params.set('skills', newValue);
    }else{
      params.delete('skills');
    }
    setSkillCategoryEntries(newValue);
    window.history.replaceState(null, '', `${location.pathname}?${params.toString()}`)
    runSearch();
  }

  return(
    <>
      <b style={{float: 'left'}} >Skills</b>
      <br />
      <TagsInput
        label=""
        placeholder="Enter Skills"
        data={skillCategories}
        value={skillCategoryEntries}
        onChange={handleSkillCategoryChange}
      />
    </>
  );
}

export default SkillTagsQ;