import React, { useEffect, useState } from 'react';
import axiosCustom from '../utilities/axiosCustom';

import { Select } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import RadiusQ from './RadiusQ';

var LocationInputQ = function( {runSearch} ) {
  const [suggestions, setSuggestions] = useState([]);
  const [locationInput, setLocationInput] = useState('');
  const [selectedCityId, setSelectedCityId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  var findSuggestions = function(){
      if(locationInput){
        axiosCustom.get('/api/locations/search/' + locationInput)
        .then(response => {
        if (response.data.locations.length > 0) {
          setSuggestions(response.data.locations);
        }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
  }

  useEffect(findSuggestions, [locationInput]);

  // Populate state with the query values.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSelectedCityId(parseInt(params.get('location')));
    
    if(params.get('location') !== selectedCityId){
        axiosCustom.get(`/api/locations/${params.get('location')}`)
        .then(response => {
            setLocationInput(response.data.location);
            setSuggestions([{label: response.data.location, value: params.get('location')}]);
        })
        .catch(error => {
            console.error('get location name error: ', error);
        });
    }
  }, []);

  // Update URL when state changes
  useEffect(() => {
  const params = new URLSearchParams(location.search);
  const currentLocationParam = params.get('location');
  if (selectedCityId !== currentLocationParam) {
    if (selectedCityId) {
      params.set('location', selectedCityId);
    } else {
      params.delete('location');
      params.delete('radius');
    }
    navigate(`?${params.toString()}`, { replace: true });
  }

  }, [selectedCityId]);

  var onSelectCity = (_value, option) => {
    // option.value is the id of the selected city
    if(_value){
      setSelectedCityId(parseInt(option.value));
    }
  }
  useEffect(runSearch, [selectedCityId])

  var onClear = () => {
    // const params = new URLSearchParams(location.search);
    // setSelectedCityId(null);
    // if(params.get('location')){
    //   params.delete('location');
    // }
    // navigate(`?${params.toString()}`, { replace: true });
    // should also delete
    setSelectedCityId(null);
    setLocationInput('');
    runSearch();
  }

  return (
    <>
      <Select
          placeholder={ "City Name" }
          data={suggestions}
          searchValue={locationInput}
          onSearchChange={(value)=>{setLocationInput(value)}}
          onChange={onSelectCity}
          allowDeselect={false}
          searchable
          onClear={onClear}
          limit={10}
          clearable
      />
      {locationInput.length ? <RadiusQ runSearch={runSearch} /> : ''}
      </>
      // <AutoComplete value={locationInput} suggestions={['a', 'b', 'c']} completeMethod={search} onChange={(e) => setLocationInput(e.value)}  />
  );
}

export default LocationInputQ;