import React, {useState, useEffect} from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import PostListItem from '../../components/PostListItem';
import axiosCustom from '../../utilities/axiosCustom';

const FavoritePosts = function() {
    const [posts, setPosts] = useState([]);

    const fetchPosts = function(){
        fetch('/api/posts/get-users-favorites')
        .then(response => response.json())
        .then(data => {
            setPosts(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    useEffect(fetchPosts, []);
    useEffect(()=>{
        console.log('posts loaded:')
    })
    const possessiveName = name => name.endsWith('s') ? `${name}'` : `${name}'s`;

    return (
        <div>
            <h2>My Favorite Posts</h2>
            <Row>
            {posts?.map((post, key)=>{
                return <PostListItem
                    title={post.title}
                    description={post.description}
                    id={post.postId}
                    author= {post.author}
                    key={key}
                    createdDateTime={post.createdDateTime}
                    appendToLink={'favorite-posts'}
                />
            })}
            </Row>
        </div>
    );
};

export default FavoritePosts;