import React, { useState, useEffect } from 'react';
import axiosCustom from '../utilities/axiosCustom';
import { useLocation, useNavigate } from 'react-router-dom';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isPasswordUpdateSuccessful, setIsPasswordUpdateSuccessful] = useState('');

    const [newPasswordFirst, setNewPasswordFirst] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    // const [stage, setStage] = useState('request'); // request,  update

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const handleSubmit = async (event) => {
        event.preventDefault();
        axiosCustom.post('/api/user/reset-password', {email: email})
        .then((response) => {
            setStatusMessage('A password reset link was sent to '+email);
            setErrorMessage('');
        })
        .catch((error) => {
            setErrorMessage('We could not send a password reset to this email. For support contact support@theperfectparttime.com');
        });
    };

    const onUpdatePassword = (event) => {
        event.preventDefault();
        axiosCustom.put('/api/user/update-password', {password: newPasswordFirst, token: params.get('token')})
        .then((response) => {
            setIsPasswordUpdateSuccessful(true);
        })
        .catch((error) => {
            console.log('error: Could not update password: error');
        })
    }

    if(params.get('token')){
        return (
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card border-0 shadow">
                    <div className="card-header bg-primary text-white rounded-top">
                        <h2 className="text-center">Update my Password</h2>
                    </div>
                    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                    {isPasswordUpdateSuccessful && <div className="alert alert-success" role="alert">Your password was updated successfully, <a href="/">click here to sign in.</a></div>}
                    <div className="card-body">
                        <form onSubmit={onUpdatePassword}>
                            <div className="form-group">
                                <label htmlFor="new-password-1">New Password:</label>
                                <input type="password" className="form-control" id="new-password-1" value={newPasswordFirst} onChange={e => setNewPasswordFirst(e.target.value)} required />
                            </div>
                            <br />
                                <button type="submit" className="btn btn-primary btn-block">Update Password</button>
                            <br />

                        </form>
                    </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return(
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card border-0 shadow">
                    <div className="card-header bg-primary text-white rounded-top">
                        <h2 className="text-center">Reset my Password</h2>
                    </div>
                    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                    {statusMessage && <div className="alert alert-success" role="alert">{statusMessage}</div>}
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                            </div>

                            <br />
                                <button type="submit" className="btn btn-primary btn-block">Send me a Password Reset Link</button>
                            <br />

                        </form>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;