import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faMessage, faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import axiosCustom from '../utilities/axiosCustom';

var PostFavoriteButton = function( { isPostFavoritedByUser } ){
  const { id } = useParams();
  var [isFavorite, setIsFavorite] = useState(0);
  var [isDisabled, setIsDisabled] = useState(false);
  
  var handleClickFavorite = function(event){
    event.preventDefault();
    setIsDisabled(true);
    axiosCustom.get(`/api/post/favorite/${id}/${isFavorite ? 0 : 1}`)
      .then(() => {
        setIsFavorite(!isFavorite);
        setIsDisabled(false);
    })
      .catch((error) => {
        console.error('Error favoriting post:', error);
    });

  }
  
  useEffect(function(){
    setIsFavorite(isPostFavoritedByUser ? 1 : 0);
  },[]);

  return(
    <>
      <Button onClick={handleClickFavorite} disabled={isDisabled} style={{marginLeft: "5px", marginRight: "5px"}} variant={`${isFavorite ? "" : 'outline-'}primary`} >Favorite Post <FontAwesomeIcon icon={faHeart} /></Button>
    </>
  );
}

const Post = function({ fetchPosts }) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [author, setAuthor] = useState('');
    const [authorId, setAuthorId] = useState('');
    const [createdDateTime, setCreatedDateTime] = useState('');
    const [isFavorite, setIsFavorite] = useState(false);

    const [hasChanged, setHasChanged] = useState(false);

    const navigate = useNavigate();
    const { id, edit } = useParams();

    var loadPost = function(){
        axiosCustom.get(`/api/post/${id}`)
          .then(response => {
            setTitle(response.data.post.title);
            setContent(response.data.post.description);
            setAuthor(response.data.post.firstName + ' ' + response.data.post.lastName);
            setCreatedDateTime(response.data.post.createdDateTime);
            setAuthorId(response.data.post.createdByUserId);
            setIsFavorite(response.data.post.isPostFavoritedByUser);
          });
    }
    
    var handleDeletePost = function(){
        axiosCustom.delete(`/api/post/${id}`)
        .then(response => {
            console.log('Successfully deleted post:', response.data);
            navigate('/posts');
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    useEffect(loadPost, [id]);

    var handleSavePost = function(){
        axiosCustom.patch(`/api/post/${id}`, {
          title: title,
          description: content
        })
      .then(response => {
        console.log('Successfully updated post:', response.data);
        navigate(`/posts/${id}`);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }
    function formatToMonthDay() {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        
        const givenDate = new Date(createdDateTime);
        const month = months[givenDate.getMonth()];
        const day = givenDate.getDate();
        const year = givenDate.getFullYear();

        return `${month}, ${day}, ${year}`;
    
    }    

    return (
        !title?.length ? <div>Loading...</div> 
        :
        (edit==='edit') ? 
            <>
                {
                    <Col l="6"><a onClick={()=>{navigate(-1)}} style={{float:'left'}} a href="#">Go Back</a></Col>
                }
                <Form>
                    <Form.Group as={Row} controlId="post-title">
                        <Form.Label className="text-left" column xs="2">Post Title</Form.Label>
                            <Form.Control value={title} onChange={(event)=>{setHasChanged(true); setTitle(event.target.value)}} placeholder="Post Title" />
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="post-body">
                        <Form.Label className="text-left" column xs="2">Post Body</Form.Label>                       
                        <Form.Control value={content} onChange={(event)=>{setHasChanged(true); setContent(event.target.value)}} placeholder="Post Body" as="textarea" rows={10} />
                    </Form.Group>
                    <Row>
                    <Col>
                        <Button onClick={()=>{navigate('/posts/'+id)}} className="ml-auto" variant="primary">
                            Discard Changes
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={!hasChanged} onClick={handleSavePost} className="ml-auto" variant="primary">
                            Save Changes
                        </Button>
                    </Col>
                    </Row>
                </Form>
            </>
        :
        <>
            {
                (window.location.search.includes('favorite-posts')) ? <><br /><Col l="6"><a onClick={()=>{navigate(-1)}} style={{float:'left'}} href="#">Back to My Favorite Posts</a></Col></> : ""
            }
            {
                (window.location.search.includes('search')) ? <><br /><Col l="6"><a onClick={()=>{navigate(-1)}} style={{float:'left'}} href="#">Back to Search</a></Col></> : ""
            }
            {
                (!window.location.search.includes('search')) ? <><br /><Col l="6"><a onClick={()=>{navigate(-1)}} style={{float:'left'}} href="#">Go Back</a></Col></> : ""
            }
            <br />
            {
                (parseInt(authorId) === parseInt(localStorage.userID)) ? 
                <>
                    <Row>
                        <Col className="d-flex align-items-center justify-content-end">
                            <PostFavoriteButton isPostFavoritedByUser={isFavorite} />
                            <Button href={('/posts/edit/'+id)} style={{marginLeft: "5px", marginRight: "5px", float: "right"}} className="buttonWithOutline" variant="outline-primary">Edit Post <FontAwesomeIcon icon={faPenToSquare} /></Button>
                            <Button onClick={handleDeletePost} style={{marginLeft: "5px", marginRight: "5px", float: "right"}} className="buttonWithOutline" variant="outline-primary">Delete Post <FontAwesomeIcon icon={faTrashCan} /></Button>
                        </Col>
                    </Row>
                </>
                :
                ""
            }

            <Row className="mt-3">
                <Col className="text-left">
                    <h1>{title}</h1>
                </Col>
            </Row>
            <Row>
                <Col className="text-left">
                    <p className='text-left text-muted'> Author: {author} </p>
                    <p className='text-left text-muted'> Posted {formatToMonthDay()} </p>
                </Col>
                <Col>
                    <Button onClick={()=>{navigate(`/messages/new/?recipientId=${authorId}`)}} variant="outline-primary" size="sm">
                        <FontAwesomeIcon icon={faMessage} /> Message {author}
                    </Button>
                </Col>

            </Row>
            <Row className="mt-3">
                <p className="text-left" dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br />') }}></p>
            </Row>
            </>
    );
};

export default Post;
