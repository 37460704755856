import React, { useState, useEffect } from 'react';
import ThreadsList from './ThreadsList.js';
import MessagesThread from './MessagesThread.js';
import { Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Message.module.css';
import axiosCustom from '../../utilities/axiosCustom';

function Messages() {
  const location = useLocation();
  const navigate = useNavigate();
  const [threads, setThreads] = useState([]);

  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [threadName, setThreadName] = useState(null);
  // const [threadId, setThreadId] = useState(null);

  const params = new URLSearchParams(location.search);

  useEffect(()=>{
    threads.forEach((thread)=>{
      if(thread.id === selectedThreadId) {
        setThreadName(thread.partnerName);
      }
    })
  }, [selectedThreadId])
  
  // useEffect(getThreads, []);
  
  const refreshThreadsList = () => {
    axiosCustom.get('/api/message/threads')
    .then(response => {
      const threads = response.data.threads;
      setThreads(threads);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const initializeThreads = () => {
        axiosCustom.get('/api/message/threads')
        .then(response => {
        const threads = response.data.threads;
        setThreads(threads);

        if (+params.get('selectedThreadId')) {
        threads.forEach((thread) => {
        if (thread.id === +params.get('selectedThreadId')) {
        setSelectedThreadId(+params.get('selectedThreadId'));
        navigate(`/messages`, { replace: true });
        }
        });
        } else {
        if (threads.length) {
        setSelectedThreadId(threads[0].id);
        }
        }
        })
        .catch(error => {
        console.error('Error:', error);
        });

  }

  useEffect(initializeThreads, []);

  // todo: Update the thread ordering with the most recently used.
  if(threads.length){
    return(
      <>
        <div className={styles.container}>
          <div className="row rounded-lg no-gutters h-100 position-relative">
            <Col lg={4} className=''>
              <ThreadsList selectedThreadId = {selectedThreadId} setSelectedThreadId = {setSelectedThreadId} threads = {threads} />
            </Col>
            <Col lg={8} className = {styles.chatMessagesContainer} >
              <MessagesThread selectedThreadId = {selectedThreadId} setSelectedThreadId = {setSelectedThreadId} threadName = {threadName} refreshThreadsList = {refreshThreadsList} />
            </Col>
          </div>
        </div>
      </>
    )
  }else {
    return(
      <>
        <h4>No messages yet. Try <a href="/profiles">starting a conversation</a> with another Perfect Part-Time user!</h4>
      </>
    )
  }

}

export default Messages;