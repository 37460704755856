import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axiosCustom from '../utilities/axiosCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

const FavoriteButton = function({ userId }){

  var [isFavorite, setIsFavorite] = useState(false);
  var [isDisabled, setIsDisabled] = useState(false);
  
  var handleClickFavorite = function(event){
    event.preventDefault();
    setIsDisabled(true);
    axiosCustom.get(`/api/user/favorite/${userId}/${!isFavorite}`)
    .then(() => {
      setIsFavorite(!isFavorite);
      setIsDisabled(false);
    })
    .catch((error) => {
      console.error('Error favoriting user:', error);
    });

  }
  
  useEffect(function(){
    axiosCustom.get(`/api/user/is-favorite/${userId}`)
    .then((response)=>{
      setIsFavorite(response.data.isFavorite);
    })
    .catch((error) => {
      console.error('Error checking if favorite:', error);
    });
  },[]);

  return(
    <>
      <Button onClick={handleClickFavorite} disabled={isDisabled} style={{marginLeft: "5px", marginRight: "5px"}} variant={`${isFavorite ? "" : 'outline-'}primary`} >Favorite Profile <FontAwesomeIcon icon={faHeart} /></Button>
    </>
  );
}

export default FavoriteButton;